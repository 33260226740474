<template>
    <div class="row">
        <div class="col-md-12">
            <table class="table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Offer Id</th>
                        <th>Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="seller in Sellers" :key="seller._id">
                        <td>{{ seller.name }}</td>
                        <td>{{ seller.email }}</td>
                        <td>{{ seller.phone }}</td>
                        <td>{{ seller.offerid }}</td>
                        <td>{{ seller.timestamp }}</td>
                        <td>
                            <router-link :to="{name: 'edit', params: { id: seller._id }}" class="btn btn-success">Edit
                            </router-link>
                            <button @click.prevent="deleteSeller(seller._id)" class="btn btn-danger">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        data() {
            return {
                Sellers: []
            }
        },
        created() {
            const configruntime = require('../configruntime');
            const port = configruntime.apiport;
            let apiURL = 'https://ibuylandusa.com/api';
            if (configruntime.environment == 'dev') {
                apiURL = 'http://localhost:' + port + '/api';
            }
            axios.get(apiURL).then(res => {
                this.Sellers = res.data;
            }).catch(error => {
                console.log(error)
            });
        },
        methods: {
            deleteSeller(id){
                const configruntime = require('../configruntime');
                const port = configruntime.apiport;
                let apiURL = `https://ibuylandusa.com/api/delete-seller/${id}`;
                if (configruntime.environment == 'dev') {
                    apiURL = 'http://localhost:' + port + `/api/delete-seller/${id}`;
                }
                
                let indexOfArrayItem = this.Sellers.findIndex(i => i._id === id);

                if (window.confirm("Do you really want to delete?")) {
                    axios.delete(apiURL).then(() => {
                        this.Sellers.splice(indexOfArrayItem, 1);
                    }).catch(error => {
                        console.log(error)
                    });
                }
            }
        }
    }
</script>

<style>
    .btn-success {
        margin-right: 10px;
    }
</style>